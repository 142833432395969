import React, { useEffect } from 'react'
import type { AppProps } from 'next/app'
import { App } from '../components/app/app'

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')

    if (jssStyles) {
      jssStyles.remove()
    }
  }, [])

  return (
    <App>
      <Component { ...pageProps } />
    </App>
  )
}

export default MyApp