import React, { useContext } from 'react'
import { createGenerateClassName, CssBaseline, MuiThemeProvider, StylesProvider, useTheme } from '@material-ui/core'
import { StylesContext } from '@material-ui/styles'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from '../../GlobalStyles'
import { store } from '../../store'
import { theme } from '../../theme'
import { BreakpointMap, RemScalingOptions } from '@atomixdesign/rem-scaling'
import { InlineFontSetter, RemScalingProvider } from '@atomixdesign/rem-scaling-react'

export type AppProps = {
  //
}

export const useThemeBreakpoints = (): BreakpointMap => {
  const theme = useTheme()
  const keys = theme.breakpoints.keys
  const breakpoints = theme.breakpoints.values

  return keys.reduce((carry, key, i) => {
    if (i <= keys.length - 1) {
      // @ts-ignore
      const prevBreakpoint = breakpoints[keys[i - 1]]

      if (prevBreakpoint) {
        carry[key] = [prevBreakpoint, breakpoints[key]]
      }
    }

    return carry
  }, {} as BreakpointMap)
}

export const App: React.FC<AppProps> = ({ children }) => {
  const themeBreakpoints = useThemeBreakpoints() // This is a helper, it can be replaced with custom code

  const remScaling: RemScalingOptions = {
    min: 12,
    max: theme.typography.fontSize,
    base: theme.typography.fontSize,
    breakpoints: themeBreakpoints,
    fallback: [0, 1600],
    nonce: process.env.nonce,
  }

  return (
    <StylesProvider injectFirst>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={theme}>
            <RemScalingProvider options={remScaling}>
              <InlineFontSetter />
              <CssBaseline />
              <GlobalStyles />
              {children}
            </RemScalingProvider>
          </MuiThemeProvider>
        </ThemeProvider>
      </Provider>
    </StylesProvider>
  )
}